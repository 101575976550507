import React, { useEffect, useMemo } from "react";
import { Button, Col, UncontrolledTooltip } from "reactstrap";
import { FaPencilAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import FeedbackModal from "./FeedbackModal";
import { StateProvider } from "./Context";
import { isAllow } from "utils";
import { Roles } from "model";

function checkRoleAndEmitToast(isAvaliator) {
  const lastToastDatetimeInMs = localStorage.getItem("lastToastDatetimeInMs");
  const isToastDisabled = localStorage.getItem("isToastDisabled");
  const now = Date.now();
  const timeForAnotherToastInMs = 600000;
  const itsTimeForANewToast = now - lastToastDatetimeInMs > timeForAnotherToastInMs;
  if (isAvaliator && itsTimeForANewToast && !isToastDisabled) {
    localStorage.setItem("lastToastDatetimeInMs", now);
    const onClick = () => {
      localStorage.setItem("isToastDisabled", true);
      toast.dismiss();
    }
    toast.info(
      <div className="d-flex flex-column justify-content-center" style={{ cursor: "default" }}>
        Não esqueça de preencher a avaliação sempre que possível!
        <div className="disable-toast" style={{ color: "#0069D9", cursor: "pointer", "&:hover": { textDecoration: "underline" } }} onClick={onClick}>Clique aqui para não ver mais essa notificação</div>
      </div>,
      { closeOnClick: false }
    );
  }
}

export function FeedbackButton({ className, avaliate, color="info", ...props }) {
  const isAvaliator = useMemo(() => isAllow([Roles.ROLE_INSURANCE_ANALYST, Roles.ROLE_INSURANCE_MANAGER, Roles.ROLE_HOTLIST_MANAGER, Roles.ROLE_INSPECTOR]), []);
  useEffect(() => { checkRoleAndEmitToast(isAvaliator && avaliate); }, [avaliate]);
  return (
    <StateProvider>
      <FeedbackModal { ...props }>
        { 
           toggle => (
              <Col xl="2" lg="3" md="4" sm="6" xs="12" className={className}>
                <div style={{ Shadow: avaliate && "0 0.5rem 1rem grey" }}>
                  <Button block onClick={toggle} disabled={!avaliate} color={color}>
                    Avaliar
                  </Button>
                </div>
              </Col>
        )
      }
      </FeedbackModal>
    </StateProvider>
  )
}

export function FeedbackEditIcon({ className, disabled, ...props }) {
  return (
    <StateProvider>
      <FeedbackModal { ...props }>
        {
          toggle => (
            <>
              <FaPencilAlt id={`edit-${props.id}`} onClick={toggle} className={className} style={{ cursor: "pointer", pointerEvents: disabled ? "none" : "auto", color: disabled ? "lightgrey" : "#212529" }} />
            <UncontrolledTooltip placement="top" target={`edit-${props.id}`}>
              Editar
              </UncontrolledTooltip>
            </>
          )
        }
      </FeedbackModal>
    </StateProvider>
  );
}
